import * as React from "react"
import { Link, makeStyles } from "@material-ui/core"
import { Box, Button, Stack, Typography } from "@mui/material"

const useStyles = makeStyles((theme) => ({
  divImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 140,
    width: 140,
    minWidth: 140,
    marginBottom: "10px",
    marginRight: "70px",
    [theme.breakpoints.down(720)]: {
      marginRight: "20px"
    },
    [theme.breakpoints.down(600)]: {
      marginRight: "0px"
    }
  },
  img: {
    width: 140,
    height: 140,
    minWidth: 140,
    marginBottom: 0
  },
  root: {
    backgroundColor: "rgba(200, 200, 200, 0.2)",
    borderRadius: "25px",
    padding: "21px"
  },
  bottomStack: {
    width: '100%',
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down(375)]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  buttonBox: {
    [theme.breakpoints.down(375)]: {
      marginTop: "25px"
    }
  }
}))

export interface CtaProps {
  noButton?: boolean
}

const CallToAction = (props: CtaProps) => {
  const classes = useStyles()

  return <Box sx={{
    paddingTop: "30px",
    paddingBottom: "30px"
  }}>
    <Stack className={classes.root} direction={{ xs: "column", sm: "row" }} justifyContent={"center"}
           alignItems={"center"}>
      <Box className={classes.divImg}>
        <img src={"/images/attila.png"} className={classes.img} alt={"Hello"} />
      </Box>
      <Box style={{ maxWidth: "390px" }}>
        {props?.noButton !== true && <Box sx={{ marginBottom: "30px", fontSize: "16px" }}>
          Azért vagyok itt, hogy megismerjem az Ön igényeit, válaszoljak minden kérdésére, és a lehető leggyorsabban
          kidolgozzam az ötletet.
        </Box>}
        {props?.noButton === true && <Box sx={{ marginBottom: "30px", fontSize: "16px" }}>
          Azért vagyok itt, hogy megismerjem az Ön igényeit és válaszoljak minden kérdésére
        </Box>}
        <Box className={classes.bottomStack}>
          <Stack direction={props?.noButton === true ? 'row' : 'column'}
                 alignItems={'center'}
          >
            <Typography sx={{ fontSize: "18px", marginRight: props?.noButton === true ? '15px':0 }}>Attila</Typography>
            <Typography sx={{ fontSize: "13px" }}>értékesítési vezető</Typography>
          </Stack>
          {props?.noButton !== true && <Box className={classes.buttonBox}>
            <Link href={"/kapcsolat"}>
              <Button sx={{
                backgroundImage: "linear-gradient(to right, #54B97D , #4CAAC3)",
                color: "#fff",
                borderRadius: "25px",
                textTransform: "none",
                padding: "5px 30px",
                fontWeight: 400
              }}>
                Üzenetet írok!
              </Button>
            </Link>
          </Box>}
        </Box>
      </Box>
    </Stack>
  </Box>
}

export default CallToAction
